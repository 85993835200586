import * as React from "react";
import { Link } from "gatsby";
import { Wrapper, Footer } from "../components";

const Impressum = () => {
    return (
        <>
            <Wrapper>
                <div className="mb-12">
                    <Link to="/"><span className="underline">Zurück zur Startseite</span></Link>
                </div>
                <div>
                    <h1 className="text-3xl">Impressum &amp; Datenschutzerkl&auml;rung</h1>
                    <p>
                        <b>Premium Cars<br />
                        Berbati &amp; Hammes GmbH</b><br />
                        Leipziger Str. 44 (Ecke Hallesche Str. 3)<br />
                        DE-14612 Falkensee bei Berlin
                    </p>

                    <p>
                        Phone: +49 3322 286 40 10<br />
                        Fax: +49 3322 286 40 11<br />
                        Cell: +49 163 400 40 33<br />
                        eMail: premium_cars@gmx.de
                    </p>
                    
                    <p>
                        Amtsgericht: Potsdam<br />
                        Handelsregisternummer: HRB 25849 P<br />
                        UST-ID: DE286350510 (Umsatzsteuer-Identifikationsnr. nach &sect; 27a Umsatzsteuergesetz)<br />
                        EORI: 261517835886014
                    </p>
                    
                    <p>Rechtsformwechsel von GbR zu GmbH am 01.01.2013</p>
                    
                    <p>
                        <b>Vertretungsberechtigte Personen:</b><br />
                        &ndash; Dipl. Kfm. Thomas Hammes<br />
                        &ndash; Besnik Berbati
                    </p>
                    
                    <p>
                        <strong>Layout und Konzeption:<br />
                        3DT UG (haftungsbeschränkt) &ndash;&nbsp;</strong><a href="https://3dt.digital/">Zur Website.</a>
                    </p>
                    
                    <p>
                        <b>&Ouml;ffnungszeiten:</b><br />
                        Mo-Fr: 09:00 Uhr &ndash; 18:00 Uhr<br />
                        Sa: 10:00 Uhr &ndash; 14:00 Uhr<br />
                        Abweichend davon gem&auml;&szlig; telefonischer Absprache
                    </p>
                    
                    <p>
                        Wir unterst&uuml;tzen den gemeinn&uuml;tzigen Verein &bdquo;L.E.N.A. e.V.&ldquo;, der zusammen mit der spanischen Organisation &bdquo;Deporte y Arte Solidario&ldquo; im Tschad Hilfe leistet.<br />
                        Wenn Sie sich informieren oder spenden m&ouml;chten nutzen Sie bitte den folgenden Link:<br /><a href="http://www.lena-ev.de/">www.lena-ev.de</a>
                    </p>
                    
                    <p>
                        <strong>Disclaimer:</strong><br />
                        Haftung f&uuml;r Inhalte<br />
                        Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>
                    
                    <p>
                        <b>Haftung f&uuml;r Links</b><br />
                        Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    </p>
                    
                    <p>
                        <b>Urheberrecht</b><br />
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </p>
                    
                    <p>
                        <b>Datenschutzerkl&auml;rung:</b><br />
                        Der Schutz und die Sicherheit von pers&ouml;nlichen Daten hat bei uns eine hohe Priorit&auml;t. Daher halten wir uns strikt an die Regeln des deutschen Bundesdatenschutzgesetzes (BDSG). Nachfolgend werden Sie dar&uuml;ber informiert, welche Art von Daten erfasst und zu welchem Zweck sie erhoben werden:
                    </p>
                    
                    <p>
                        <b>1. Daten&uuml;bermittlung /Datenprotokollierung</b><br />
                        Beim Besuch dieser Seite verzeichnet der Web-Server automatisch Log-Files, die keiner bestimmten Person zugeordnet werden k&ouml;nnen. Diese Daten beinhalten z. B. den Browsertyp und -version, verwendetes Betriebssystem, Referrer URL (die zuvor besuchte Seite), IP-Adresse des anfragenden&nbsp;Rechners, Zugriffsdatum und -uhrzeit der Serveranfrage und die Dateianfrage des Client (Dateiname und URL). Diese Daten werden nur zum Zweck&nbsp;der statistischen Auswertung gesammelt. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, findet nicht statt.
                    </p>
                    
                    <p>
                        <b>2. Nutzung pers&ouml;nlicher Daten</b><br />
                        Pers&ouml;nliche Daten werden nur erhoben oder verarbeitet, wenn Sie diese Angaben freiwillig, z.B. im Rahmen einer Anfrage mitteilen. Sofern keine&nbsp;erforderlichen Gr&uuml;nde im Zusammenhang mit einer Gesch&auml;ftsabwicklung bestehen, k&ouml;nnen Sie jederzeit die zuvor erteilte Genehmigung Ihrer<br />
                        pers&ouml;nlichen Datenspeicherung mit sofortiger Wirkung schriftlich (z.B. per E-Mail oder per Fax) widerrufen. Ihre Daten werden nicht an Dritte&nbsp;weitergeben, es sei denn, eine Weitergabe ist aufgrund gesetzlicher Vorschriften erforderlich.
                    </p>
                    
                    <p>
                        <b>3. Auskunft, &Auml;nderung und L&ouml;schung Ihrer Daten</b><br />
                        Gem&auml;&szlig; geltendem Recht k&ouml;nnen Sie jederzeit bei uns schriftlich nachfragen, ob und welche personenbezogenen Daten bei uns &uuml;ber Sie&nbsp;gespeichert sind. Eine entsprechende Mitteilung hierzu erhalten Sie umgehend.
                    </p>
                    
                    <p>
                        <b>4. Sicherheit Ihrer Daten</b><br />
                        Ihre uns zur Verf&uuml;gung gestellten pers&ouml;nlichen Daten werden durch Ergreifung aller technischen sowie organisatorischen Sicherheitsma&szlig;nahmen so&nbsp;gesichert, dass sie f&uuml;r den Zugriff unberechtigter Dritter unzug&auml;nglich sind. Bei Versendung von sehr sensiblen Daten oder Informationen ist es&nbsp;empfehlenswert, den Postweg zu nutzen, da eine vollst&auml;ndige Datensicherheit per E-Mail nicht gew&auml;hrleistet werden kann.
                    </p>
                    
                    <p>
                        <b>5. Cookies</b><br />
                        Auf einigen unserer Seiten verwenden wir sog. &bdquo;Session-Cookies&ldquo;, um Ihnen die Nutzung unserer Webseiten zu erleichtern. Dabei handelt es sich um kleine Textdateien, die nur f&uuml;r die Dauer Ihres Besuchs unserer Webseite auf Ihrer Festplatte hinterlegt und abh&auml;ngig von der Einstellung Ihres Browser-Programms beim Beenden des Browsers wieder gel&ouml;scht werden. Diese Cookies rufen keine auf Ihrer Festplatte &uuml;ber Sie gespeicherten Informationen ab und beeintr&auml;chtigen nicht Ihren PC oder ihre Dateien. Die meisten Browser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Sie k&ouml;nnen das Speichern von Cookies jedoch deaktivieren oder ihren Browser so einstellen, dass er Sie auf die Sendung von Cookies hinweist.
                    </p>
                    
                    <p>
                        <b>6. &Auml;nderungen dieser Datenschutzbestimmungen</b><br />
                        Wir werden diese Richtlinien zum Schutz Ihrer pers&ouml;nlichen Daten von Zeit zu Zeit aktualisieren. Sie sollten sich diese Richtlinien gelegentlich ansehen, um auf dem Laufenden dar&uuml;ber zu bleiben, wie wir Ihre Daten sch&uuml;tzen und die Inhalte unserer Website stetig verbessern. Sollten wir wesentliche &Auml;nderungen bei der Sammlung, der Nutzung und/oder der Weitergabe der uns von Ihnen zur Verf&uuml;gung gestellten personenbezogenen Daten vornehmen, werden wir Sie durch einen eindeutigen und gut sichtbaren Hinweis auf der Website darauf aufmerksam machen. Mit der Nutzung der Webseite erkl&auml;ren Sie sich mit den Bedingungen dieser Richtlinien zum Schutz pers&ouml;nlicher Daten einverstanden.
                    </p>
                    
                    <p>
                        Bei Fragen zu diesen Datenschutzbestimmungen wenden Sie sich bitte &uuml;ber unsere Kontakt-Seite an uns.
                    </p>

                </div>                                               
            </Wrapper>
            <Footer />
        </>
    )
}

export default Impressum;